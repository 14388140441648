enum FeaturedOptionEnum {
    FEATURED = 'featured',
    NOT_FEATURED = 'not featured'
}

enum CouponExpirationOptionEnum {
    EXPIRED = 'expired',
    NOT_EXPIRED = 'not expired',
}

enum LinkRelOptionEnum {
    FOLLOW = 'follow',
    NOFOLLOW = 'nofollow',
}

enum CryptoBookLevelEnum {
    EASY = 'easy',
    MEDIUM = 'medium',
    HARD = 'hard',
}

enum StatusEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    UNTRACKED = 'untracked',
    CMC_ACTIVE = 'cmc_active',
    CMC_INACTIVE = 'cmc_inactive',
    CMC_UNTRACKED = 'cmc_untracked',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
}

enum ReportStatusEnum {
    PENDING = 'pending',
    PROCESSING = 'processing',
    RESOLVED = 'resolved',
}

enum ContentStatusEnum {
    PUBLISHED = 'published',
    DRAFT = 'draft',
}

enum OptionEnum {
    ALL = 'All',
    YES = 'yes',
    NO = 'no',
    WITHOUT_CONTENT = 'without content',
    WITH_CONTENT = 'with content',
    WITHOUT_DESCRIPTION = 'without description',
    WITH_DESCRIPTION = 'with description'
}

enum QuotableTypeEnum {
    NEWS_ARTICLE = 'NewsArticle',
    REVIEW = 'Review',
    TUTORIAL = 'Tutorial',
    CATEGORY = 'Category',
}

enum NewsArticleCategoryEnum {
    AI = 'ai',
    ALTCOINS = 'altcoins',
    ANALYSIS = 'analysis',
    BLOCKCHAIN = 'blockchain',
    BREAKING_NEWS = 'breaking-news',
    BUSINESS = 'business',
    DIGITAL_CURRENCY_NEWS = 'digital-currency-news',
    DAPPS = 'dapps',
    DEFI = 'defi',
    EXCHANGES = 'exchanges',
    EXPLOITS = 'exploits',
    INTERVIEWS = 'interviews',
    MARKET_NEWS = 'market-news',
    NEWS = 'news',
    NFTS = 'nfts',
    OPINION = 'opinion',
    PRESS_RELEASES = 'press-releases',
    REGULATION = 'regulation',
    WALLETS = 'wallets',
}

enum CustomerSupportRatingEnum {
    VERY_BAD = 'very_bad',
    BAD = 'bad',
    AVERAGE = 'average',
    GOOD = 'good',
    EXCELLENT = 'excellent'
}

enum CustomerSupportRatingEnumLabels {
    VERY_BAD = 'Very Poor',
    BAD = 'Bad',
    AVERAGE = 'Average',
    GOOD = 'Good',
    EXCELLENT = 'Excellent'
}

enum UserStatusEnum {
    ACTIVE = 'active',
    DISABLED = 'disabled',
    BANNED = 'banned',
}

enum MarketingCampaignVersionEnum {
    MAIN = 1,
    INTERNATIONAL = 2,
}

enum MarketingCampaignDefaultContentTypeEnum {
    WALLET = 1,
    OTHER = 2,
}

enum MarketingCampaignsCounterResetTypeEnum {
    ONE_DAY = '1d',
    THREE_DAYS = '3d',
    NONE = '',
}

enum QuestGuildVerificationStatusEnum {
    NOT_VERIFIED = 'not_verified',
    IN_PROGRESS = 'in_progress',
    VERIFIED = 'verified',
}

const ReportableTypeEnum = {
    QUEST_GUILD: 'QuestGuild',
    QUEST_MISSION: 'QuestMission',
    QUEST_ROUND: 'QuestRound',
    QUEST_ACTION: 'QuestAction',
};

const ReportReasonEnum = {
    COPYRIGHT: 'copyright',
    MISINFORMATION: 'misinformation',
    TECHNICAL_ISSUE: 'technical_issue',
    SCAM: 'scam',
    PHISHING: 'phishing',
    ABUSIVE: 'abusive',
    OTHER: 'other',
};

export {
    FeaturedOptionEnum,
    CouponExpirationOptionEnum,
    CustomerSupportRatingEnumLabels,
    LinkRelOptionEnum,
    CryptoBookLevelEnum,
    StatusEnum,
    ContentStatusEnum,
    OptionEnum,
    QuotableTypeEnum,
    NewsArticleCategoryEnum,
    CustomerSupportRatingEnum,
    UserStatusEnum,
    MarketingCampaignVersionEnum,
    MarketingCampaignDefaultContentTypeEnum,
    MarketingCampaignsCounterResetTypeEnum,
    QuestGuildVerificationStatusEnum,
    ReportableTypeEnum,
    ReportReasonEnum,
    ReportStatusEnum,
};
